<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Depósitos</h4>
            <div class="small text-muted">Administración de todos los depósitos</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Depósito">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0 deposit-table-min-height"
                     responsive="sm"
                     head-variant="dark"
                     :hover="true"
                     :small="true"
                     :fixed="false"
                     :items="table.items"
                     :fields="table.fields"
                     :filter="table.filter"
                     :current-page="table.currentPage"
                     :per-page="table.perPage"
                     :busy="table.isBusy"   
                     selectable
                     select-mode="single"                     
                     v-if="table.items.length || table.isBusy">

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(width)="data">
                  <div v-if="data.item.width">
                    {{data.item.width}} Metros
                  </div>
                </template>

                <template v-slot:cell(height)="data">
                  <div v-if="data.item.height">
                    {{data.item.height}} Metros
                  </div>
                </template>  

                <template v-slot:cell(zone)="data">                  
                  <div v-if="data.item.active" v-html="getZoneObservation(data.item)"></div>
                </template>                                                

                <template v-slot:cell(active)="data">
                  <div v-if="!data.item.active" title="Depósito Inactivo">
                    <b-icon icon="circle-fill" animation="throb" variant="danger" font-scale="2"></b-icon>
                  </div>
                  <div v-else>
                    <b-icon icon="circle-fill" variant="success" font-scale="1"></b-icon>
                  </div>      
                </template>        

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="configZone(data.item)" v-if="data.item.active">
                      <b-icon icon="gear"></b-icon> Configuración de Zonas
                    </b-dropdown-item>       

                    <b-dropdown-header>Activo/Inactivo</b-dropdown-header>  
                    <b-dropdown-item @click="active(data.item)" v-if="!data.item.active">
                      <b-icon icon="eye"></b-icon> Activar
                    </b-dropdown-item>                     
                    <b-dropdown-item @click="active(data.item)" v-if="data.item.active">
                      <b-icon icon="eye-slash"></b-icon> Desactivar
                    </b-dropdown-item>                     
                    
                    <b-dropdown-header>Acciones</b-dropdown-header>  
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Referencia">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.reference"
                                required
                                :readonly="!crud.form.id"
                                placeholder="Referencia">
                  </b-form-input>
                </b-form-group>
              </b-col>    
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="3">
                <b-form-group label="Ancho" description="Ancho en metros">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.height"
                                @change="changeInput">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Largo" description="Largo en metros">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.width"
                                @change="changeInput">
                  </b-form-input>
                </b-form-group>
              </b-col>  
              <b-col md="3">
                <b-form-group label="P.H." description="Pasillos Horizontales">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.rows"
                                @change="changeInput">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="P.V." description="Pasillos Verticales">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.cols"
                                @change="changeInput">
                  </b-form-input>
                </b-form-group>
              </b-col>                
            </b-row>
          </b-col>
        </b-row>      
        <b-row>      
          <b-col id="crud-deposit-area-canva" class="mt-3">
            <canvas id="canvas"></canvas>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>  

      <!-- CRUD SUBMAIN -->
      <b-modal v-model="modal.formSub.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>
          <b-col md="4" class="crud-deposit-zone-divide">
            <b-row>
              <b-col md="12" class="mb-2">
                <span class="crud-deposit-zone-legend">Depósito</span>                
              </b-col>

              <b-col md="12" v-if="selectDeposit">
                <b-form-group>
                  <b-form-input type="text"
                                size="sm"
                                v-model="selectDeposit.name"
                                required
                                :readonly="true"
                                placeholder="Depósito">
                  </b-form-input>
                </b-form-group>
              </b-col>  

              <b-col md="12" v-if="selectDeposit">
                <b-form-group>
                  <b-form-input type="text"
                                size="sm"
                                v-model="selectDeposit.reference"
                                required
                                :readonly="true"
                                placeholder="Referencia">
                  </b-form-input>
                </b-form-group>
              </b-col>  

              <b-col md="12" id="crud-deposit-zone-area-canva">               
                <canvas id="canvas-zone"></canvas>            
              </b-col>
            </b-row>
          </b-col>
          <b-col md="4" class="crud-deposit-zone-divide">
            <b-row v-if="crud.formSub.id">
              <b-col md="12" v-if="crud.formSub.deposit" class="mb-2">
                <span class="crud-deposit-zone-legend">Zona</span>
              </b-col>              
              <b-col md="12">
                <b-row>             
                  <b-col md="12">
                    <b-form-group>
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formSub.name"
                                    required
                                    :readonly="true"
                                    placeholder="Nombre">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formSub.reference"
                                    required
                                    :readonly="!crud.formSub.id"
                                    placeholder="Referencia">
                      </b-form-input>
                    </b-form-group>
                  </b-col>    
                  <b-col md="12">
                    <b-form-checkbox v-model="crud.formSub.active" 
                                      switch 
                                      size="sm" 
                                      class="mb-4 pull-left">
                      Activar
                    </b-form-checkbox>            
                  </b-col>                    
                  <b-col md="12" class="">
                    <b-row>
                      <b-col md="12">
                        <b-form-group label="Filas">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.formSub.rows"
                                        required                                    
                                        placeholder="Filas">
                          </b-form-input>
                        </b-form-group>
                      </b-col>    
                      <b-col md="12">
                        <b-form-group label="Columnas">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.formSub.cols"
                                        required                                    
                                        placeholder="Columnas">
                          </b-form-input>
                        </b-form-group>                    
                      </b-col>      
                    </b-row>
                  </b-col>                    
                  <b-col md="12">
                    <b-button variant="dark" class="w-100" size="sm" @click="refreshZone()">Actualizar Zona</b-button>          
                  </b-col>                                       
                </b-row>
              </b-col>
            </b-row>
            <div v-else class="container h-100">
              <div class="row align-items-center h-100">
                <div class="col-12 mx-auto">                  
                  <b-alert show variant="info">
                    Seleccione una zona para acceder a su configuración
                  </b-alert>                  
                </div>
              </div>              
            </div>
          </b-col>
          <b-col md="4" v-if="crud.formSub.id">
            <b-row v-if="crud.formSub.rows && crud.formSub.cols">
              <b-col md="12" v-if="crud.formSub.deposit">
                <span class="crud-deposit-zone-legend">Ubicaciones</span>
                <b-alert variant="warning" show class="mt-1">Click en la ubicación para activarla o inactivarla</b-alert>
              </b-col>    
              <b-col md="12" id="crud-deposit-location-area-canva">
                <canvas id="canvas-location"></canvas>            
              </b-col>
            </b-row>
            <div v-else class="container h-100">
              <div class="row align-items-center h-100">
                <div class="col-12 mx-auto">                  
                  <b-alert show variant="info">
                    Configure las filas y columnas de la zona
                  </b-alert>                  
                </div>
              </div>              
            </div>            
          </b-col>
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cerrar</b-button>                
        </div>        
      </b-modal>    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'    
  import Storage from '@/handler/storageSession'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.DEPOSITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudDeposits',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'name', label: 'Nombre', class: 'align-middle'},            
            {key: 'reference', label: 'Referencia', class: 'align-middle'},          
            {key: 'width', label: 'Largo', class: 'align-middle'},      
            {key: 'height', label: 'Ancho', class: 'align-middle'},      
            {key: 'zone', label: 'Zonas', class: 'align-middle text-center'},      
            {key: 'active', label: 'Activo', class: 'align-middle text-center'},    
            {key: 'f_action', label:'', class: 'align-middle'},
          ],
          isBusy: false,
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },          
        crud: {
          form: {
            id: 0,
            name: '',  
            reference: '',
            width: 1,       
            height: 1,
            rows: 0,
            cols: 0,
            active: true,
            zones: [],
          },
          formSub: {          
            id: 0,  
            deposit: null,
            deposit_id: 0,    
            name: '',        
            reference: '',
            rows: 0,
            cols: 0,      
            posX: 0,
            posY: 0,
            active: true, 
            locations: [],     
          },     
          formLocation: {
            id: 0,  
            name: '',        
            reference: '',
            active: true,            
          },     
        },
        selectDeposit: null,
        arr: {
          deposits: [],
        },
        modal: {
          form: {
            active: false,
            title: ''
          },   
          formSub: {
            active: false,
            title: ''
          },                     
        },       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()      
    },
    watch: {
      'crud.form.width': function () {      
        this.getDepositCanvas()
      },
      'crud.form.height': function () {              
        this.getDepositCanvas()
      },
      'crud.form.rows': function () {      
        this.getDepositCanvas()
      },
      'crud.form.cols': function () {              
        this.getDepositCanvas()
      },                                 
    },
    methods: { 
      // remder          
      getRowCount (items) {
        return items.length
      },

      // crud deposito
      load() {
        this.table.isBusy = true
        var result = serviceAPI.obtenerDepositos()

        result.then((response) => {
          var data = response.data          
          this.table.items = data
          this.arr.deposits = data
          
          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.reference = ''
        this.crud.form.width = 1
        this.crud.form.height = 1
        this.crud.form.rows = 0
        this.crud.form.cols = 0
        this.crud.form.active = true

        this.modal.form.title = "Nuevo Depósito"
        this.modal.form.active = true

        setTimeout(()=>{
          this.getDepositCanvas()
        },100)
        
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.reference = item.reference
        this.crud.form.width = item.width
        this.crud.form.height = item.height
        this.crud.form.rows = item.rows
        this.crud.form.cols = item.cols
        this.crud.form.active = item.active        

        this.modal.form.title = "Editar Depósitos"
        this.modal.form.active = true

        setTimeout(()=>{
          this.getDepositCanvas()
        },100)        
      },
      remove(item) {
        this.crud.form.id = item.id

        this.$bvModal.msgBoxConfirm('¿Desea borrar el depósito (' + item.id + ') - '+ item.name + '?', {
          title: 'Borrar Depósito',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarDeposito(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Depósito eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        this.crud.form.zones = JSON.parse(Storage.getValue('deposit_zone_graphic'))

        if (this.crud.form.id) {
          var result = serviceAPI.editarDeposito(this.crud.form);
        } else {
          var result = serviceAPI.agregarDeposito(this.crud.form);
        }
        
        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Depósito guardado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },     
      active(item) {        
        var msj = ''
        var title = ''
        var variant = ''
        var msjResult = ''
        if(item.active) {
          msj = "¿Desea desactivar el depósito?"
          title = "Desactivar Depósito"
          variant = 'danger'
          msjResult = 'Depósito desactivado'
        } else {
          msj = "¿Desea activar el depósito?"
          title = "Activar Depósito"
          variant = "success"
          msjResult = 'Depósito activado'
        }

        this.$bvModal.msgBoxConfirm(msj, {
          title: title,
          size: 'lg',
          buttonSize: 'lg',
          okVariant: variant,
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: variant,
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {

            let loader = this.$loading.show();

            this.crud.form.id = item.id
            this.crud.form.name = item.name
            this.crud.form.reference = item.reference
            this.crud.form.width = item.width
            this.crud.form.height = item.height
            this.crud.form.rows = item.rows
            this.crud.form.cols = item.cols
            this.crud.form.active = !item.active  
            
            var result = serviceAPI.editarDeposito(this.crud.form);

            result.then((response) => {        
              loader.hide()
              this.load()
              this.$awn.success(msjResult);                    
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })        
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },

      // canvas deposito
      getZoneObservation(item) {

        if(item.zones.length) {
          var count = 0
          item.zones.forEach(element => {
            if(element.active) {
              count = count + 1
            }
          });

          return '<span class="font-weight-bold text-info">' + count + ' Activas</span>';
        } else {
          return '<span class="font-weight-bold text-danger">Sin Zonas</span>';
        }
        
      },      
      changeInput() {
        if(this.crud.form.height<1) {        
          this.crud.form.height = 1
          this.$awn.alert("El valor no puede ser igual o menor a cero")          
        }
        if(this.crud.form.width<1) {        
          this.crud.form.width = 1
          this.$awn.alert("El valor no puede ser igual o menor a cero")          
        }              
        if(this.crud.form.rows<0) {        
          this.crud.form.rows = 0
          this.$awn.alert("El valor no puede ser negativo")          
        }            
        if(this.crud.form.cols<0) {        
          this.crud.form.cols = 0
          this.$awn.alert("El valor no puede ser negativo")          
        }              
      },      
      getDepositCanvas() {          
        var width = parseFloat(this.crud.form.width)
        var height = parseFloat(this.crud.form.height)
        var rows = parseFloat(this.crud.form.rows)
        var cols = parseFloat(this.crud.form.cols)

        if(width>0 && height>0) {
          if(width > height) {
            this.drawDeposit('crud-deposit-area-canva', 'canvas', 350, 200, width + ' metros', height + ' metros', rows, cols, true, false, null)
          } 
          if(width < height) {
            this.drawDeposit('crud-deposit-area-canva', 'canvas', 200, 350, width + ' metros', height + ' metros', rows, cols, true, false, null)
          }            
          if(width == height) {
            this.drawDeposit('crud-deposit-area-canva', 'canvas', 350, 350, width + ' metros', height + ' metros', rows, cols, true, false, null)
          }            
        }         
      },
      drawDeposit(area, element, width, height, textWidth, textHeight, rows, cols, showText, clickZone, arrZoneConfig) {        
        var arrZones = []
        const areaCanvas = document.getElementById(area);    
        const canvas = document.getElementById(element);  
                  
        if(areaCanvas && canvas) {
          canvas.width = areaCanvas.clientWidth - 30;
          canvas.height = areaCanvas.clientHeight;      
          
          if(clickZone) {
            this.clickDespositZone(element)
          }

          const ctx = canvas.getContext("2d");

          let x = (canvas.width-parseFloat(width))/2
          let y = (canvas.height-parseFloat(height))/2      
          
          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#000000";
          ctx.fillStyle = "#b3b3b3";         
          ctx.rect(x, y, width, height);
          ctx.stroke();
          ctx.fill();    

          if(showText) {
            ctx.beginPath();
            ctx.textAlign='center';
            ctx.font='20px Arial';
            ctx.fillStyle="#000000";
            ctx.fillText(textWidth,(x + (parseFloat(width)/2)), (y - 5));
            
            ctx.beginPath();
            ctx.textAlign='end';
            ctx.font='20px Arial';
            ctx.fillStyle="#000000";
            ctx.fillText(textHeight,(x - 5), (y + (parseFloat(height)/2)));            
          }
          
          rows = parseFloat(rows) + 1
          cols = parseFloat(cols) + 1

          let widthZone = parseFloat(width)
          let heightZone = parseFloat(height)      
          if(cols) {widthZone = parseFloat(width) / parseFloat(cols)}
          if(rows) {heightZone = parseFloat(height) / parseFloat(rows)}

          let count = 1
          for (let index = 0; index < rows; index++) {                      
              x = (canvas.width-parseFloat(width))/2
              for (let index1 = 0; index1 < cols; index1++) {                                  
                  var active = true                  
                  if(arrZoneConfig) {                    
                    active = arrZoneConfig[index][index1].active
                  } 

                  arrZones.push({
                    rows: index,
                    cols: index1,
                    ejeX: x,
                    ejeY: y,
                    width: widthZone,                
                    height: heightZone,
                  })

                  ctx.beginPath();
                  ctx.lineWidth = 1;                  
                  ctx.strokeStyle = "#000000";

                  if(active) {
                    ctx.fillStyle = "#4dbd74";                
                  } else {
                    ctx.fillStyle = "#f86c6b";                
                  }

                  ctx.rect(x, y, widthZone, heightZone);
                  ctx.stroke();
                  ctx.fill();  
                  
                  ctx.beginPath();
                  let textWidth = "Z: " + count
                  ctx.textAlign='center';
                  ctx.font='12px Arial';
                  ctx.fillStyle="#000000";
                  ctx.fillText(textWidth,x + (widthZone/2), y + (heightZone/2));

                  count = count + 1
                  x += widthZone;
              }
              y += heightZone;
          }      
        }

        Storage.removeValue('deposit_zone_graphic')
        Storage.setValue('deposit_zone_graphic', JSON.stringify(arrZones))    
      },
      clickDespositZone(element) {    
        const canvas = document.getElementById(element);     
        canvas.removeEventListener("click", this.eventClickDespositZone);     
        canvas.addEventListener('click', this.eventClickDespositZone, false);  
      },   
      eventClickDespositZone(event) {  
        var x = event.layerX;
        var y = event.layerY;
        
        var arrZones = JSON.parse(Storage.getValue('deposit_zone_graphic'))
        
        if(arrZones) {
          arrZones.forEach(element => {                        
            if( element.ejeX < x && (element.ejeX + element.width) > x &&
                element.ejeY < y && (element.ejeY + element.height) > y ) {
              this.getZoneByPosition(element)
            }
          });    
        }
      },      
            
      // canvas deposito zona
      configZone(item) {                
        this.crud.formSub.deposit = item
        this.crud.formSub.deposit_id = item.id

        this.crud.formSub.id = 0
        this.crud.formSub.name = ''
        this.crud.formSub.reference = ''
        this.crud.formSub.active = true

        this.modal.formSub.title = "Configuración de Zonas"
        this.modal.formSub.active = true

        setTimeout(()=>{
          this.getZonasCanvas(item)
        },100)        
      },
      getZonasCanvas(item) {
        this.selectDeposit = item

        var width = parseFloat(item.width)
        var height = parseFloat(item.height)
        var rows = parseFloat(item.rows)
        var cols = parseFloat(item.cols)

        var arrZone = []
        if(item.zones) {
          item.zones.forEach(element => {
            
            if(arrZone[element.posX] == undefined)
              arrZone[element.posX] = []
            
            if(arrZone[element.posX][element.posY] == undefined)
              arrZone[element.posX][element.posY] = []
            
            arrZone[element.posX][element.posY] = {
              id: element.id,              
              active: element.active
            }

          });
        }        

        if(width>0 && height>0) {
          if(width > height) {
            this.drawDeposit('crud-deposit-zone-area-canva', 'canvas-zone', 300, 150, width + ' metros', height + ' metros', rows, cols, false, true, arrZone)
          } 
          if(width < height) {
            this.drawDeposit('crud-deposit-zone-area-canva', 'canvas-zone', 150, 300, width + ' metros', height + ' metros', rows, cols, false, true, arrZone)
          }            
          if(width == height) {
            this.drawDeposit('crud-deposit-zone-area-canva', 'canvas-zone', 250, 250, width + ' metros', height + ' metros', rows, cols, false, true, arrZone)
          }            
        }             
      },
      getZoneByPosition(element) {                
        let loader = this.$loading.show();

        var result = serviceAPI.obtenerZonaByPosition({
          deposit_id: this.crud.formSub.deposit_id,
          posX: element.rows,
          posY: element.cols,
        })

        result.then((response) => {
          loader.hide()         

          var data = response.data    

          this.crud.formSub.id = data.id
          this.crud.formSub.name = data.name
          this.crud.formSub.reference = data.reference
          this.crud.formSub.rows = data.rows
          this.crud.formSub.cols = data.cols
          this.crud.formSub.posX = data.posX
          this.crud.formSub.posY = data.posY
          this.crud.formSub.active = data.active      
          
          setTimeout(()=>{
            this.generateLocations(data, 'draw-from-data')
          },100)          
        })
        .catch(error => {
          loader.hide()          
        })        
      },
      refreshZone() {
        let loader = this.$loading.show();
        
        this.generateLocations(this.crud.formSub, 'draw')        
        this.crud.formSub.locations = JSON.parse(Storage.getValue('deposit_zone_location_graphic'))        

        var result = serviceAPI.editarZona(this.crud.formSub);
        
        result.then((response) => {                    
          loader.hide()                 
          var data = response.data          
          this.$awn.success("Zona actualizada con éxito");

          if(this.selectDeposit) {            
            if(this.selectDeposit.zones) {
              this.selectDeposit.zones.forEach(element => {
                if(element.id == data.id) {
                  element.name = data.name
                  element.reference = data.reference
                  element.rows = data.rows
                  element.cols = data.cols                  
                  element.active = data.active
                }
              });
            }            
            this.getZonasCanvas(this.selectDeposit)

            setTimeout(()=>{
              this.generateLocations(data, 'draw-from-data')
            },100)              
          }
        })
        .catch(error => {          
          this.getZoneByPosition({
            rows: this.crud.formSub.posX,
            cols: this.crud.formSub.posY,
          })

          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // canvas deposito ubicaciones
      generateLocations(item, type) {        
        var width = 250
        var height = 250
        var rows = parseFloat(item.rows)
        var cols = parseFloat(item.cols)

        var arrLocations = []
        if(item.locations) {
          item.locations.forEach(element => {
            
            if(arrLocations[element.posX] == undefined)
              arrLocations[element.posX] = []
            
            if(arrLocations[element.posX][element.posY] == undefined)
              arrLocations[element.posX][element.posY] = []
            
            arrLocations[element.posX][element.posY] = {
              id: element.id,              
              active: element.active
            }

          });
        }    

        if(type=='draw') {
          this.drawLocations('crud-deposit-location-area-canva', 'canvas-location', width, height, rows, cols, true, null)
        }
        if(type=='draw-from-data') {
          this.drawLocations('crud-deposit-location-area-canva', 'canvas-location', width, height, rows, cols, true, arrLocations)
        }
        
      },
      drawLocations(area, element, width, height, rows, cols, clickLocation, arrLocationConfig) {        
        var arrLocations = []
        const areaCanvas = document.getElementById(area);    
        const canvas = document.getElementById(element);  
                  
        if(areaCanvas && canvas) {
          canvas.width = areaCanvas.clientWidth - 30;
          canvas.height = areaCanvas.clientHeight;      
          
          if(clickLocation) {
            this.clickDespositLocation(element)
          }

          const ctx = canvas.getContext("2d");

          let x = (canvas.width-parseFloat(width))/2
          let y = (canvas.height-parseFloat(height))/2      
                              
          rows = parseFloat(rows)
          cols = parseFloat(cols)

          let widthZone = parseFloat(width)
          let heightZone = parseFloat(height)      
          if(cols) {widthZone = parseFloat(width) / parseFloat(cols)}
          if(rows) {heightZone = parseFloat(height) / parseFloat(rows)}

          let count = 1
          for (let index = 0; index < rows; index++) {                      
              x = (canvas.width-parseFloat(width))/2
              for (let index1 = 0; index1 < cols; index1++) {                                  
                  var active = true                                    
                  if(arrLocationConfig) {                    
                    active = arrLocationConfig[index][index1].active
                  } 

                  arrLocations.push({
                    rows: index,
                    cols: index1,
                    ejeX: x,
                    ejeY: y,
                    width: widthZone,                
                    height: heightZone,
                  })

                  ctx.beginPath();
                  ctx.lineWidth = 1;                  
                  ctx.strokeStyle = "#000000";

                  if(active) {
                    ctx.fillStyle = "#4dbd74";                
                  } else {
                    ctx.fillStyle = "#f86c6b";                
                  }

                  ctx.rect(x, y, widthZone, heightZone);
                  ctx.stroke();
                  ctx.fill();  
                  
                  ctx.beginPath();
                  let textWidth = "U: " + count
                  ctx.textAlign='center';
                  ctx.font='12px Arial';
                  ctx.fillStyle="#000000";
                  ctx.fillText(textWidth,x + (widthZone/2), y + (heightZone/2));

                  count = count + 1
                  x += widthZone;
              }
              y += heightZone;
          }      
        }

        Storage.removeValue('deposit_zone_location_graphic')
        Storage.setValue('deposit_zone_location_graphic', JSON.stringify(arrLocations))    
      },     
      clickDespositLocation(element) {    
        const canvas = document.getElementById(element);     
        canvas.removeEventListener("click", this.eventClickDespositLocation);     
        canvas.addEventListener('click', this.eventClickDespositLocation, false);  
      },   
      eventClickDespositLocation(event) {  
        var x = event.layerX;
        var y = event.layerY;
        
        var arrLocations = JSON.parse(Storage.getValue('deposit_zone_location_graphic'))
        
        if(arrLocations) {
          arrLocations.forEach(element => {                        
            if( element.ejeX < x && (element.ejeX + element.width) > x &&
                element.ejeY < y && (element.ejeY + element.height) > y ) {
              this.getLocationByPosition(element)
            }
          });    
        }
      },  
      getLocationByPosition(element) {              
        let loader = this.$loading.show();

        var result = serviceAPI.obtenerUbicacionByPosition({
          zone_id: this.crud.formSub.id,
          posX: element.rows,
          posY: element.cols,
        })

        result.then((response) => {
          loader.hide()         

          var data = response.data    

          this.crud.formLocation.id = data.id
          this.crud.formLocation.name = data.name
          this.crud.formLocation.reference = data.reference
          this.crud.formLocation.active = data.active     
          
          this.setStatusLocations(this.crud.formLocation)
        })
        .catch(error => {
          loader.hide()          
        })       
      },

      setStatusLocations(item) {
        var msj = ''
        var title = ''
        var variant = ''        

        if(item.active) {
          msj = '¿Desea desactivar ubicación "' + item.reference + '"?'
          title = 'Desactivar Ubicación'
          variant = 'danger'                  
          this.crud.formLocation.active = false
        } else {
          msj = '¿Desea activar ubicación "' + item.reference + '"?'
          title = 'Activar Ubicación'
          variant = 'success'          
          this.crud.formLocation.active = true
        }

        this.$bvModal.msgBoxConfirm(msj, {
          title: title,
          size: 'lg',
          buttonSize: 'lg',
          okVariant: variant,
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: variant,
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();
            var result = serviceAPI.editarUbicacion(item);

            result.then((response) => {      
              loader.hide() 
              var data = response.data

              this.getZoneByPosition({
                rows: data.zone.posX,
                cols: data.zone.posY,
              })

              this.$awn.success("Estado de la ubicación actualizada");
            })
            .catch(error => {
              loader.hide() 
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },      
    }    
  }
</script>

<style>
  .deposits-zone-name-title {
    font-size: 17px;
  }
  .deposit-table-min-height {
    min-height: 75px;
  }
  #crud-deposit-area-canva {
    height: 400px;
  }
  #crud-deposit-zone-area-canva {
    height: 350px;
  }  
  #crud-deposit-location-area-canva {
    height: 400px;
  }  
  .crud-deposit-zone-divide {
    border-right-style: dotted;
    border-right-width: 2px;
  }
  .crud-deposit-zone-legend {
    font-weight: bold;
    text-decoration: underline;
    margin-right: 5px;
    font-size: 15px;
  }
  .crud-deposit-zone-title {
    text-transform: uppercase;
    font-size: 15px;
  }
</style>